@import '../../assets/styles/variables.scss';

.home-amount{
    text-align: center;
    font-size: 20px;
}

.home-amount-small{
    text-align: center;
    font-size: 16px;
}

.money-label{
    text-align: center;
    color: $COLOR_primary;
}