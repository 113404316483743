@import '../../assets/styles/variables.scss';

.link-copy{
    border: 1px solid #969494;
    border-radius: 20px;
    color: $COLOR_primary;
    width: 100%;
    &:hover{
        cursor: pointer;
    }
    .text {
        padding: 10px;
        color: $COLOR_primary;
        white-space: pre-wrap;
        word-break: break-all;
        path{
            color: $COLOR_primary;
        }
    }
    
}

.text {
    padding: 10px;
    color: $COLOR_primary;
    path{
        color: $COLOR_primary;
    }
}