// FONTSIZE
$FONT_small: 18px;
$FONT_medium: 26px;
$FONT_large: 38px;

// COLORS
$COLOR_text_active: #464646;
$COLOR_text_inactive: #8a8a8a;

$COLOR_primary: #f47223;
$COLOR_secondary: #aacdbe;

$COLOR_white_background: #fafafa;
$COLOR_blue: #013c4c;
$COLOR_blue_hover: #00607b;
$COLOR_gradient_default: linear-gradient(
  90deg,
  rgba(0, 108, 141, 1) 0%,
  rgba(124, 207, 135, 1) 100%
);
$COLOR_button_default: #efefef;

// MIXINS
@mixin shadowDefault {
  -webkit-box-hadow: 0px 0px 28px -18px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 28px -18px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 28px -18px rgba(0, 0, 0, 0.16);
}

@mixin shadowRaised {
  -webkit-box-hadow: 0px 7px 18px -6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 7px 18px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 7px 18px -6px rgba(0, 0, 0, 0.3);
}

@mixin middle {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@mixin x-middle {
  transform: translateX(-50%);
  left: 50%;
}

@mixin y-middle {
  transform: translateY(-50%);
  top: 50%;
}
