@import '../../assets/styles/variables.scss';

.home-value{
    font-size: 20px;
    text-align: right;
}

.home-activity-entry{
    font-size: 16px;
    padding: 15px 20px;
}

.options-container{
    border: 0.5px solid #46464644;
    border-radius: 5px;
    min-width: 600px;
}

.user-row{
    cursor: pointer;
}