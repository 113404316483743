@import '../../assets/styles/variables.scss';

.staff-details {
    .staff-details-container {
        .staff-info {
            border: 1px solid black;
            border-radius: 20px;
            width: 820px;
            height: 300px;
            padding: 20px;
            .staff-title {
                padding: 5px;
            }
        }
        .permission-card {
            border: 1px solid black;
            border-radius: 20px;
            width: 400px;
            height: 200px;
            .permission-title,
            .permission-content {
                padding: 20px;
            }
        }
        .permission-card-show {
            border: 1px solid black;
            border-radius: 20px;
            width: 400px;
            height: 400px;
            .permission-title,
            .permission-content {
                padding: 20px;
            }
        }
    }
}
