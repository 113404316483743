@import '../../assets/styles/variables.scss';

.proposals {
    .table-container {
        border: 1px solid $COLOR_primary;
        border-radius: 20px;
        padding: 20px;
    }
    .proposal-row {
        cursor: pointer;
    }
}
