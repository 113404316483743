@import '../../assets/styles/variables.scss';

.sidebar{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    // @media (max-width: 1023px){
    //     width: 80px;
    // }
    height: 100vh;
    background-color:#fee2b3;
    z-index: 3;
    -webkit-box-shadow: -7px 0px 14px -1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: -7px 0px 14px -1px rgba(0, 0, 0, 0.15);
    box-shadow: -7px 0px 14px -1px rgba(0, 0, 0, 0.15);
    >div{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 20px 0;
        .logoContainer{
            display: block;
            margin-bottom: 15px;
            padding: 0 23px;
            
            h1.logo{
                font-size: 23px;
                display: inline-block;
                margin: auto;
                margin-left: 14px;
                
                color: #f47223; 
            }
            .logoIcon *{
                color: $COLOR_primary;
            }
            .main_logo{
                width: 183px;
                content: url("../../assets/images/4giveslogo.png");
                // @media (max-width: 1023px){
                //     width: 40px;
                //     content: url("../../assets/images/4giveslogoorange.png");
                // }
            }
            *{
                vertical-align: middle;
            }
        }
        .navigation{
            padding: 0;
            margin: 0;
            display: block;
            li{
                display: block;
                padding: 0 15px;
                
                background-color: #fee2b3;
                &:hover{
                    .menuItem{
                        background-color: #9c703b2b;
                    }
                    *{
                        color: #f47223; 
                    }
                }
              
                .menuItem{
                    display: block;
                    padding: 12px 8px 12px 10px;
                    border-radius: 7px;
                    transition: all 0.2s ease-in-out;
                    background-color: #fee2b3;
                    text-decoration: none;

                }
                .iconContainer{
                    width: 30px;
                    text-align: center;
                    margin: 0px 12px 0px 0px;
                    display: inline-block;
                    // @media (max-width: 1023px){
                    //    margin: auto;
                    //    display: block;
                    // }
                    
                    .menuIcon{
                        font-size: 20px;
                    }
                }
                span{
                    font-size: 16px;
                    color: #f47223;
                    max-height: 22px;
                    font-weight: 600;
                    // @media (max-width: 1023px){
                    //     display: none;
                    // }
                }
                *{
                    vertical-align: middle;
                    transition: all 0.2s ease-in-out;
                    color: $COLOR_text_inactive;
                    
                }
            }
        }
    }
}