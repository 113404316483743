

.img-container{
    
    text-align: center;
    cursor: pointer;
    
    img{
        padding: 10px;
        width: 170px;
        border: 1px solid #ececec;
        border-radius: 5px;
        transition: border 0.5s;
        height: 170px;
        text-align: center;
        object-fit: contain;
    }
    img:hover{
        border: 1px solid #f47723;
    }
}




.img-container-big{
    border-radius: 5px;
    text-align: center;
    transition: border 0.5s;
    img{
        padding: 10px;
        width: 250px;
        height: 250px;
        border: 1px solid #ececec;
        border-radius: 5px;
        text-align: center;
        object-fit: contain;
    }
}