@import '../../assets/styles/variables.scss';


.transactions{
    
    .table-container{
        border: 1px solid $COLOR_primary;
        border-radius: 20px;
        padding: 20px;
        
    }
    .transaction-row{
        cursor: pointer;
    }
}