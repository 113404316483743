@import '../../assets/styles/variables.scss';


.transaction-details{
    
    .transaction-details-container{
        .proposal-details, .proposal-actors, .proposal-numbers{
            border: 0.5px solid $COLOR_primary;
            border-radius: 20px;
            padding: 20px;
            
        }
        .proposal-actors{
            width: 450px;
            height: 309px;
        }
        .proposal-numbers{
            max-width: 925px;
            @media (max-width: 1280px){
                width: 450px;
            }
        }
        
    }
}