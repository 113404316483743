@import '../../assets/styles/variables.scss';

.fg-button {
    color: $COLOR_text_inactive;
    text-align: center;
    padding: 6px 19px;
    border-radius: 20px;
    border: 0;
    &:hover {
        cursor: pointer;
    }
}

.fg-button:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.fg-button-primary {
    background-color: $COLOR_primary;
    color: #fff;
}

.fg-button-login-primary {
    background-color: #fff;
    color: $COLOR_primary;
    border-color: $COLOR_primary;
    border: 1px solid $COLOR_primary;
    border-radius: 20px;
}

.fg-button-login-secondary {
    background-color: $COLOR_primary;
    color: #fff;
    border-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
}

.fg-button-secondary {
    background-color: $COLOR_secondary;
}

.fg-button-hover {
    background-color: $COLOR_button_default;
    transition: all 0.2s ease-in;
    &:hover {
        background-color: $COLOR_primary;
        color: #fff;
    }
}

.fg-button-block {
    display: block;
    width: 100%;
}

.fg-button-link {
    display: inline-block;
    background-color: transparent;
    padding: 0;
    border: 0;
    &:hover {
        text-decoration: underline;
    }
}

.button-link {
    color: $COLOR_blue;
    text-decoration: none;
    font-size: 25px;
    font-weight: bold;
}

.transact-button {
    width: 120px;
}

.fg-home {
    font-size: 20px;
    @media (max-width: 959px) {
        font-size: 16px;
    }
}

.add-user {
    padding: 10px 50px 10px 50px;
}
