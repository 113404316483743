@import '../../assets/styles/variables.scss';


.staffs{
    
    .table-container{
        border: 1px solid $COLOR_primary;
        border-radius: 20px;
        padding: 20px;
        
    }
    .staff-row{
        cursor: pointer;
    }
}