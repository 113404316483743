@import '../../assets/styles/variables.scss';

.login {
  width: 100%;
  height: 100%;
  background-color: #ececec;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 50px 0px;
  @media (max-width: 767px) {
    padding: 20px 0px;
    overflow-y: auto;
  }
  text-align: center;
  .login-container {
    width: 500px;

    @media (max-width: 767px) {
      width: 80vw;
    }
    border-radius: 50px;
    padding: 30px 45px;
    background-color: $COLOR_primary;
    margin: auto;
    .main_logo {
      width: 183px;
      content: url('../../assets/images/4giveslogo_white.png');
    }
    .label {
      color: white;
    }
    .form-container {
      margin: 25px 0;
    }
  }
}
