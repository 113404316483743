@import '../../assets/styles/variables.scss';

.user-details {
    .user-details-container{
        padding-bottom: '50px';
        .user-source-row{
            cursor: pointer;
        }
    }
}

.image-container{
    position: relative;
}

.image {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 200px;
}

.image:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.image-buttons{
    transition: .2s ease;
    position: absolute;
    opacity: 0;
    top: 72%;
    left: 65%;
    text-align: center;
}

.image-container:hover .image-buttons {
    opacity: 1;
  }

