@import '../../assets/styles/variables.scss';

.fg-card{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    position: relative;
    border: 1px solid;
    border-color: #f47223;
    .fg-card-title{
        font-size: 18px;
        font-weight: 600;
        color: $COLOR_primary;
    }
    .fg-card-body{
        flex: 1;
        padding-top: 20px;
        p{
            font-size: 12px;
        }
    }
    .fg-card-footer{
        display: inline-block;
        margin-top: 10px;
        width: 100%;
        * {
            float: right;
        }
    }
}