@import '../../assets/styles/variables.scss';

.page-wrapper{
    display: block;
    padding-left: 250px;
    // @media (max-width: 1023px){
    //     padding-left: 80px;
    // }
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: $COLOR_white_background;

    .user-menu-container{
        position: absolute;
        right: 1;
        top: 50px;
        transition: all 0.2s ease-in;
        transform: scale(0);
        transform-origin: top right;
        z-index: 100;
        &:after{
            content: '';
            position: absolute;
            top: -6px;
            left: auto;
            right: 23px;
            width: 0;
            height: 0;
            border: 13px solid transparent;
            border-bottom-color: #ffffff;
            margin-left: -8px;
            margin-top: -20px;
        }
        .user_menu{
            background-color: #fff;
            position: relative;
            background-color: #fff;
            display: inline-block;
            
            list-style-type: none;
            padding: 9px 0;
            margin: 0;
            width: 150px;
            border-radius: 7px;
            overflow: hidden;
            @include shadowRaised;
           
            li{
                padding: 4px 15px;
                font-size: 14px;
                color: $COLOR_text_inactive;
                background-color: #fff;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                img{
                    width: 26px;
                    height: auto;
                }
                span{
                    margin-left: 8px;
                    font-size: 14px;
                }
                &:hover{
                    background-color: #eee;
                }
            }
        }
    }
    .user-menu-container-open{
        transform: scale(1);
    }

    .page-content{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 20px 15px 70px;
        z-index: 1;
        overflow-y: scroll;
        padding-top: 20px;
        h1.page-title{
            margin-top: 15px;
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 25px;
        }
        h2.page-title{
            margin-top: 22px;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
            color: $COLOR_primary;
        }
        p{
            font-size: 12px;
            margin-bottom: 0;
        }
        span{
            font-size: 20px;
        }
    }
    
}
