@import './variables.scss';

html,
* {
  box-sizing: border-box;
}
body {
  position: relative;
  margin: 0;
  width: 100vw;
  height: 100vh;
  display: inline-block;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  a {
    text-decoration: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
  }
  #root {
    width: 100vw;
    height: 100vh;
  }
  * {
    font-family: 'Montserrat', sans-serif;
    //color: $COLOR_text_active;
    outline: none;
    font-size: 16px;
    @media (max-width: 500px) {
      font-size: 13px;
    }
    /* width 
        &::-webkit-scrollbar {
            width: 8px;
        }
        /* Track 
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        /* Handle 
        &::-webkit-scrollbar-thumb {
            background: $COLOR_primary;
            border-radius: 10px;
        }*/
  }
}

.fg-container {
  background-color: transparent;
  padding: 20px;
  border-radius: 20px;
  display: inline-block;

  @include shadowDefault;
}

.button-tool {
  padding: 10px 15px;
  background-color: #f47223;
  border: 1px solid #c55e27;
  border-radius: 10px;
  cursor: pointer;
}
.button-tool-disabled {
  padding: 10px 15px;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  border-radius: 10px;
}

.button-tool:hover {
  background-color: #e9bfa5;
  border: 1px solid #e9bfa5;
}

.button-tool-small {
  padding: 5px 10px;
  background-color: #f47223;
  border: 1px solid #c55e27;
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
}

.button-tool-small-penalty {
  padding: 5px 10px;
  background-color: #f43123;
  border: 1px solid #f43123;
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
}

.button-tool-disabled {
  padding: 5px 10px;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  font-size: 13px;
  border-radius: 10px;
}

.button-tool:hover {
  background-color: #e9bfa5;
  border: 1px solid #e9bfa5;
}

.button-small-tool {
  padding: 10px 15px;
  background-color: #f47223;
  border: 1px solid #f47223;
  border-radius: 20px;
  cursor: pointer;
  width: 200px;
  text-align: center;
}

.table-title {
  padding: 20px;
  text-align: center;
}

.button-small:hover {
  background-color: #e9bfa5;
  border: 1px solid #e9bfa5;
}

.button-delete {
  padding: 10px 15px;
  background-color: #f42323;
  border: 1px solid #f42323;
  border-radius: 10px;
  cursor: pointer;
}

.button-delete:hover {
  background-color: #e9a5a5;
  border: 1px solid #e9a5a5;
}
