@import '../../assets/styles/variables.scss';

.fg-input{
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 7px;
    position: relative;
    input{
        flex: 1;
        width: 100%;
        appearance: none;
        border: 0;
        background-color: transparent;
        outline: none;
        
    }
    p.errorMessage{
        position: absolute;
        font-size: 11px;
        bottom: -30px;
        left: 0;
        margin: auto;
        color: red;
    }
    .fg-input-icon-container{
        margin-right: 15px;
        * {
            vertical-align: middle;
            color: $COLOR_text_inactive;
        }
    }
}

.fg-inputlogin{
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 7px;
    position: relative;
    input{
        flex: 1;
        width: 100%;
        appearance: none;
        border: 0;
        background-color: transparent;
        outline: none;
        
    }
    p.errorMessage{
        position: absolute;
        font-size: 11px;
        bottom: -18px;
        left: 0;
        margin: 0;
        color: red;
    }
    .fg-input-icon-container-login{
        margin-right: 15px;
        * {
            vertical-align: middle;
            color: white;
        }
    }
    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }
}

.fg-input-file{
    border-bottom: 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    display: inline-block;
    .fg-fileupload{
        opacity: 0;
        position: absolute;
        left: -1000px;
    }
    .fg-fileinput-icon{
        * {
            transition: all 0.2s ease;
            color: #eaeaea;
        }
    }

    .uploadList{
        display: inline-block;
        width: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li{
            width: 100%;
            border: 1px solid #dedede;
            border-radius: 10px;
            padding: 6px 10px;
            position: relative;
            @include shadowDefault;
            margin-bottom: 9px;
            span, svg{
                display: inline-block;
                vertical-align: middle;
                path{
                    color: $COLOR_primary;
                }
            }
            span{
                font-size: 12px;
                margin-left: 12px;
            }
            .removeUpload{
                position: absolute;
                @include y-middle;
                right: 12px;
                cursor: pointer;
                border-radius: 100%;
                padding: 0px 7px;
                &:hover{
                    background-color: $COLOR_primary;
                    color: #fff;
                }
            }
        }
    }

    label{
        width: 100%;
        text-align: center;
        cursor: pointer;
        &:hover{
            svg path{
                color: $COLOR_primary;
            }
        }
        .fg-uploadbutton{
            width: 125px;
            display: block;
            margin: auto;
            margin-top: 12px;
            background-color: $COLOR_primary;
            color: #FFF;
            border-radius: 20px;
            padding: 3px 0;
            font-size: 12px;
        }
    }
}

.pesoSign{
    svg{
        max-width: 17px;
        height: auto;
        path{
            fill: $COLOR_text_inactive;
        }
    }
    
}